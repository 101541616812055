import * as tslib_1 from "tslib";
import { ConstActions } from './../../consts/positionActions.const';
import { OnInit, AfterViewInit, OnDestroy, EventEmitter, OnChanges, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { DatatableService } from './datatable.service';
import { Page } from './datatable.page.interface';
import { MatSnackBar, DateAdapter } from '@angular/material';
import { MatDialog } from '@angular/material';
import { DescriptionPopupComponent } from './description-popup/description-popup.component';
import { HelpersService } from '../../services/helpers/helpers.service';
import { PrivateFilterComponent } from './private-filter/private-filter.component';
import { InputDialogComponent } from '../input-dialog/input-dialog.component';
import { WsAppsSettingsComponent } from '../../apps/ws-apps-settings/ws-apps-settings.component';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import * as _ from 'lodash';
import { OfficeHelpersService } from '../../services/office-helpers/office-helpers.service';
import { RecordBulkUpdateComponent } from './record-bulk-update/record-bulk-update.component';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { DtTableReportPopupComponent } from '../../components/dt-table-report-popup/dt-table-report-popup.component';
import { WorkspaceQuery } from '../../state/workspaceState/workspace.query';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
var DatatableComponent = /** @class */ (function () {
    function DatatableComponent(router, route, translationLoader, translate, datatableService, snackBar, dialog, helperService, _changeDetectorRef, _officeHelper, _fuseSidebarService, translationService, _adapter, workspaceQuery) {
        var _this = this;
        this.router = router;
        this.route = route;
        this.translationLoader = translationLoader;
        this.translate = translate;
        this.datatableService = datatableService;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.helperService = helperService;
        this._changeDetectorRef = _changeDetectorRef;
        this._officeHelper = _officeHelper;
        this._fuseSidebarService = _fuseSidebarService;
        this.translationService = translationService;
        this._adapter = _adapter;
        this.workspaceQuery = workspaceQuery;
        this.loadingIndicator = true;
        this.mask = [/[0-2]/, /[0-3]/, ':', /[0-5]/, /\d/];
        this.unsubscribe = new Subject();
        this.mqFilterString = "";
        this.allData = {};
        this.requestData = {};
        this.timeFilter = {
            startTime: '00:00',
            endTime: '23:59'
        };
        this.dateTimeFilterType = 'custom';
        this.valueFilter = {};
        this.refreshOfficeFilter = 0;
        this.lastSearchPersisted = false;
        this.init = true;
        this.updateOnSave = new EventEmitter();
        this.fastQuery = '';
        this.selected = [];
        this.loading = false;
        this.enableMoreActionIcon = false;
        this.filters = {};
        this.columns = [];
        this.page = new Page();
        this.dataTablesParameters = {};
        this.wsRequiredFieldDone = true;
        this.errorMessage = '';
        this.mqHardFilterString = '';
        this.currentMQFilter = '';
        this.open = false;
        this.inlineEditCols = [];
        this.inlineEditCell = {};
        this.cardVars = {};
        this.cardVarsArray = [];
        this.dtTableAdvanceFilters = [];
        this.chartData = [];
        this.rowCalculations = {};
        this.colCalculations = {};
        this.summaryArray = [];
        this.searchText = '';
        this.processFilters = {};
        this.showTotalRow = false;
        this.items = {};
        this.tagFilterInput = {};
        this.filterValue = {};
        this._adapter.setLocale(this.translationService.getDefaultLang());
        this.route.queryParams.subscribe(function (params) {
        });
        this.initDateFilter();
        // translations
        this.helperService
            .getTrans('datatable')
            .then(function (data2) {
            _this.translationLoader.loadTranslationsV2(data2);
        })
            .catch(function (err) {
            console.info(err);
        });
        this.currentLang = this.translate.currentLang || 'en';
        this.translate.onLangChange.subscribe(function (event) {
            _this.currentLang = event['lang'] || _this.translate.currentLang;
        }, function (err) {
            console.info(err);
        });
    }
    DatatableComponent.prototype.ngOnChanges = function (changes) {
        this.fastQuery = '';
        this.summaryArray = [];
        this.requestData = {};
        this.getStoredFilterForView();
        // this.appData = {};
        this.dateTimeFilterType = (this.appData && this.appData.defaultDateFilter) || '##df_all##';
        this.valueFilter = {
            'registredDataTime': (this.appData && this.appData.defaultDateFilter) || '##df_all##'
        };
        if ((changes.regId && !changes.regId.firstChange) || (changes.appData && !changes.appData.firstChange)) {
            this.rows = null;
            this.init = true;
            this.requestData = {};
            this.initDateFilter();
            this.resetFilterValues();
            this.setFromLocalData();
            this.getServerSideRecord();
        }
    };
    DatatableComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.processFilters = {};
        this.getOfficeTags();
        this.initDateFilter();
        this.setFromLocalData();
        this.getServerSideRecord();
        this.checkWSRequiredField();
        this.datatableService.onPositionRecordChange.subscribe(function (isChanged) {
            if (_this.datatableService.applicationType === 'position' && isChanged) {
                _this.updateParentRecord();
            }
        });
        this.datatableService.reRenderData.subscribe(function (reRender) {
            if (reRender && _this.regId) {
                _this.rows = null;
                _this.checkWSRequiredField();
                _this.getServerSideRecord();
            }
        });
        this.datatableService.onFilterChanged.subscribe(function (filters) {
            if (filters && Object.keys(filters).length) {
                _this.checkWSRequiredField();
                _this.requestData['filters'] = filters || {};
                if (_this.allData && Object.keys(_this.allData).length) {
                    _this.getServerSideRecord();
                }
            }
        }, function (err) {
            _this.filters = _this.filters || {};
            console.info(err);
            _this.snackBar.open(err || 'Error occured while getting data', 'Okay', {
                duration: 2000
            });
        });
        this.page.size = (this.allData && this.allData['length']) || 15;
        this.resetValues();
        this.formChartAndInlineEditVariable();
        this.workspaceQuery.workspaceDoc.pipe(takeUntil(this.unsubscribe)).subscribe(function (workspaceDoc) {
            if (workspaceDoc) {
                _this.workspaceData = JSON.parse(JSON.stringify(workspaceDoc));
            }
        });
    };
    DatatableComponent.prototype.setFromLocalData = function () {
        if (this.datatableService.applicationType !== 'position') {
            var tt = (localStorage.getItem('wsAppData') && JSON.parse(localStorage.getItem('wsAppData'))) || {};
            var appSlug = tt['appUrlSlug'] || tt['appSlug'];
            var temp = (localStorage.getItem(appSlug) && JSON.parse(localStorage.getItem(appSlug))) || {};
            this.dataTablePastRequestData = temp;
            if (temp && Object.keys(temp).length) {
                this.lastSearchPersisted = true;
                this.reopenFilters();
            }
            else {
                this.lastSearchPersisted = false;
            }
            if (temp && temp.valueFilter) {
                console.log("this.allData.filters.values 444", this.allData.filters.values);
                this.valueFilter = temp.valueFilter;
            }
            if (temp && temp.requestData && Object.keys(temp.requestData)) {
                delete temp.requestData.page;
                this.requestData = temp.requestData || {};
                this.requestData.filters = this.requestData.filters || {};
                this.dateTimeFilterType = (this.appData && this.appData.defaultDateFilter) || '##df_all##';
                this.requestData.filters = Object.assign(this.requestData.filters, {
                    'registredDataTime': (this.appData && this.appData.defaultDateFilter) || '##df_all##'
                });
                console.log("this.allData.filters.values 555", this.allData.filters.values);
                this.valueFilter = this.requestData.filters;
            }
            this.timeFilter = temp.timeFilter || this.timeFilter;
            this.dateTimeFilterType = temp.dateTimeFilterType || this.dateTimeFilterType;
        }
    };
    DatatableComponent.prototype.setLocalData = function () {
        if (this.datatableService.applicationType !== 'position') {
            var tt = JSON.parse(localStorage.getItem('wsAppData')) || {};
            var temp = {
                requestData: this.requestData,
                openedFilters: {
                    advanceFilter: (this.allData && this.allData.filters && this.allData.filters.noAdvanceFiltersSelected),
                    dateRangeFilter: (this.allData && this.allData.showDateRange),
                    superFilter: (this.allData && this.allData.showSuperFilter),
                },
                dateTimeFilterType: this.dateTimeFilterType,
                valueFilter: this.valueFilter,
                timeFilter: this.timeFilter
            };
            var appSlug = tt['appUrlSlug'] || tt['appSlug'];
            localStorage.setItem(appSlug, JSON.stringify(temp));
        }
    };
    DatatableComponent.prototype.resetLocalData = function () {
        if (this.datatableService.applicationType !== 'position') {
            var tt = JSON.parse(localStorage.getItem('wsAppData')) || {};
            var temp = {
                requestData: {},
                openedFilters: {
                    advanceFilter: (this.allData && this.allData.filters && this.allData.filters.noAdvanceFiltersSelected),
                    dateRangeFilter: (this.allData && this.allData.showDateRange),
                    superFilter: (this.allData && this.allData.showSuperFilter),
                },
                dateTimeFilterType: ''
            };
            this.initDateFilter();
            var appSlug = tt['appUrlSlug'] || tt['appSlug'];
            localStorage.setItem(appSlug, JSON.stringify(temp));
        }
    };
    DatatableComponent.prototype.ngOnDestroy = function () {
        var tt = JSON.parse(localStorage.getItem('wsAppData')) || {};
        delete tt['statusId'];
        delete tt['instanceId'];
        localStorage.setItem('wsAppData', JSON.stringify(tt));
        this.resetValues();
        this.rows = [];
        this.datatableService.instanceFilters = {};
        this.datatableService.onOptionChange['observers'] = [];
        this.datatableService.reRenderData['observers'] = [];
        this.datatableService.onFilterChanged['observers'] = [];
        this.datatableService.onPositionRecordChange['observers'] = [];
        this.unsubscribe.next();
        this.unsubscribe.complete();
    };
    DatatableComponent.prototype.checkWSRequiredField = function () {
        var _this = this;
        this.wsRequiredFieldDone = true;
        if (this.appData &&
            this.appData.requiredDataFields &&
            this.appData.requiredDataFields.length) {
            this.appData.requiredDataFields =
                this.appData.requiredDataFields || [];
            this.appData.requiredDataFields.forEach(function (ele) {
                if (ele.value === null || ele.value === undefined) {
                    _this.wsRequiredFieldDone = false;
                }
            });
        }
    };
    DatatableComponent.prototype.reInitColumnTotal = function () {
        var _this = this;
        if (this.allData && this.allData['columns']) {
            this.allData['columns'].forEach(function (element) {
                if (element && element['control_type'] && element['control_type']['allTotal'] && element['control_type']['control_type'] !== 'calculation_on_save') {
                    _this.colCalculations[element['control_type']['label']] = 0;
                    _this.showTotalRow = true;
                }
            });
        }
    };
    DatatableComponent.prototype.redirectCall = function (eve, functionName) {
        this[functionName].apply(this, eve);
    };
    DatatableComponent.prototype.onSort = function (event) {
        this.requestData['sort'] = (event.sorts && event.sorts[0]) || { dir: 'desc', prop: '_id' };
        this.allData['sort'] = event.sorts;
        this.getServerSideRecord();
    };
    DatatableComponent.prototype.setPage = function (pageInfo) {
        this.page.pageNumber = pageInfo.offset;
        this.loadingIndicator = true;
        this.getServerSideRecord();
    };
    DatatableComponent.prototype.getFilteredActions = function (actions, type) {
        actions = actions || [];
        actions = actions.filter(function (action) {
            return action &&
                action.module &&
                (action.module === 'customRegisterRecordsList' ||
                    action.module === 'addressbook');
        });
        actions = actions.filter(function (action) {
            return action && action.placement && action.placement[type];
        });
        if (type === 'dropdownActions' && actions.length) {
            this.enableMoreActionIcon = true;
        }
        return actions;
    };
    DatatableComponent.prototype.makeReqBody = function (filters, mqFilter) {
        this.allData = this.allData || {};
        this.postGetData();
        this.requestData = this.requestData || {};
        if (!this.init) {
            this.requestData['page'] = this.page;
        }
        this.requestData['mqFilter'] = '';
        if (this.requestData['currentMQFilter'] && this.appData && this.appData['hardFilter']) {
            this.requestData['mqFilter'] = this.appData['hardFilter'] + " AND " + this.requestData['currentMQFilter'];
            // delete this.requestData['currentMQFilter'];
        }
        else if (this.requestData['currentMQFilter']) {
            this.requestData['mqFilter'] = this.requestData['currentMQFilter'];
            // delete this.requestData['currentMQFilter'];
        }
        else if (this.appData && this.appData['hardFilter']) {
            this.requestData['mqFilter'] = this.appData['hardFilter'];
        }
        if (mqFilter) {
            if (this.requestData['mqFilter']) {
                this.requestData['mqFilter'] = this.requestData['mqFilter'] + " AND " + mqFilter;
            }
            else {
                this.requestData['mqFilter'] = mqFilter;
            }
        }
        // in case of position request
        if (this.datatableService.params && this.datatableService.params['postionsLookupSettings']) {
            this.requestData['positionFilters'] = this.datatableService.params['postionsLookupSettings'] || {};
            var filterKey = (this.requestData['positionFilters'] && this.requestData['positionFilters']['field']) || 'parentId';
            this.requestData.mqFilter = this.requestData.mqFilter || '';
            if (typeof (this.datatableService.params.parentId) === "string") {
                if (this.requestData.mqFilter) {
                    this.requestData.mqFilter = this.requestData.mqFilter + " AND " + filterKey + "='" + this.datatableService.params.fieldValue + "'";
                }
                else {
                    this.requestData.mqFilter = filterKey + "='" + this.datatableService.params.fieldValue + "'";
                }
            }
            else {
                this.requestData.filters = tslib_1.__assign({}, this.requestData.filters, this.datatableService.params.filter);
            }
        }
        console.log("req filter", this.requestData.filter);
        this.requestData['search'] = {
            value: this.fastQuery || '',
            regex: false
        };
        if (this.datatableService && this.datatableService.applicationType === 'workspace') {
            this.appData = this.appData || this.datatableService.appData || {};
            var wsData = (localStorage.getItem('wsAppData') && JSON.parse(localStorage.getItem('wsAppData'))) || {};
            this.requestData['workspace'] = {
                index: wsData.stepNumber || 0
            };
        }
        this.getFilters();
    };
    DatatableComponent.prototype.getServerSideRecord = function (filters, mqFilter) {
        var _this = this;
        this.makeReqBody(filters, mqFilter);
        if (this.regId) {
            this.setLocalData();
            this.loadingIndicator = true;
            // this.helperService.postRequest(`api/reg/${this.regId}/count-data-table`, this.requestData)
            //   .then((page: any) => {
            //     this.page = page;
            //   }).catch(err => {
            //     console.error(err);
            //   });
            if (this.datatableService.params && this.datatableService.params.formId) {
                this.requestData.formId = this.datatableService.params.formId;
            }
            this.helperService.postRequest("api/reg/" + this.regId + "/data-table", this.requestData)
                .then(function (data) {
                console.log('---------  ', data);
                _this.loadingIndicator = false;
                var tt = (data['dttable'] && data['dttable']['data']) || [];
                _this.rowCalculations = data['rowCalculations'] || [];
                _this.datatableService.integratedProcessId = data['integratedProcessId'];
                _this.datatableService.formId = data['formId'];
                _this.page = (data['dttable'] && data['dttable']['page']) || [];
                if (_this.init) {
                    _this.allData['showDateRange'] = data.showDateRange;
                    _this.datatableService.allData = data || {};
                    _this.postGetData();
                    _this.allData['showDateRange'] = data.showDateRange;
                    // this.requestData['currentMQFilter'] = data['currentMQFilter'] || '';
                    _this.init = false;
                    if (_this.datatableService.applicationType === 'position') {
                        data['actions'] = JSON.parse(JSON.stringify(ConstActions));
                        // hideDeleteButton
                        if (_this.fieldData && !_this.fieldData.showDeleteButton) {
                            // delete 
                            data['actions'].splice(2, 1);
                            // if price list reg selected.
                            if (!(_this.fieldData && _this.fieldData.postionsLookupSettings && _this.fieldData.postionsLookupSettings.price_list_regId)) {
                                data['actions'].splice(2, 1);
                            }
                        }
                        else {
                            // if price list reg selected.
                            if (!(_this.fieldData && _this.fieldData.postionsLookupSettings && _this.fieldData.postionsLookupSettings.price_list_regId)) {
                                data['actions'].splice(3, 1);
                            }
                        }
                    }
                    if (_this.datatableService.applicationType === 'selection') {
                        data['actions'].push({
                            type: 'function',
                            handler: function (id) {
                                // this.http.put()
                            },
                            translations: {
                                pl: 'dttable.select',
                                en: 'dttable.select'
                            },
                            default_label: 'dttable.select',
                            placement: {
                                recordActions: true
                            },
                            module: 'customRegisterRecordsList'
                        });
                        data['actions'].push({
                            'default_label': 'dttable.back',
                            'translations': {
                                'pl': 'dttable.back',
                                'en': 'dttable.back'
                            },
                            'icon': '',
                            'sort': '',
                            'fuse2App': './../',
                            'placement': {
                                'headerActions': true,
                                'subheaderActions': false,
                                'recordActions': false,
                                'dropdownActions': false
                            },
                            'module': 'customRegisterRecordsList',
                            'application': 'app.customregistries.back',
                            'params': {},
                            'applicationName': 'Go Back',
                            'access_level': '2',
                            'actionParams': ''
                        });
                    }
                    // now format the data...
                    data['actions'] = _this.datatableService.formatActions(data['actions'], data);
                    _this.allData = data;
                    _this.postGetData();
                    _this.allData['columns'] = (data && data['columns']) || [];
                    if (_this.appData && _this.appData.showSubAction) {
                        _this.allData['columns'].push({
                            'prop': 'subAction',
                            'name': 'subAction',
                            'isPullOnly': false
                        });
                    }
                    _this.allData['columnMode'] = 'flex';
                    _this.allData['headerHeight'] = 50;
                    _this.allData['footerHeight'] = 50;
                    _this.allData['rowHeight'] = 'auto';
                    _this.allData['externalPaging'] = true;
                    _this.defaultHardFilter = data['defaultFilter'] || null;
                    if (_this.datatableService && _this.datatableService.applicationType !== 'workspace') {
                        _this.allData['columns'].push({
                            prop: '_id',
                            name: 'Actions',
                            class: 'action-txt-align',
                            sortable: false,
                            'isPullOnly': false,
                            render: _this.getFilteredActions(data['actions'], 'recordActions').concat(_this.getFilteredActions(data['actions'], 'dropdownActions'))
                        });
                    }
                    else if (_this.datatableService && _this.datatableService.applicationType === 'workspace' && _this.appData && !_this.appData.hideActionColumn) {
                        _this.allData['columns'].push({
                            prop: '_id',
                            name: 'Actions',
                            class: 'action-txt-align',
                            sortable: false,
                            'isPullOnly': false,
                            render: _this.getFilteredActions(data['actions'], 'recordActions').concat(_this.getFilteredActions(data['actions'], 'dropdownActions'))
                        });
                    }
                    _this.allData['headerActions'] = _this.getFilteredActions(data['actions'], 'headerActions') || [];
                    _this.allData['filters'] = data['filters'] || {};
                    // show or hide filters....
                    _this.reopenFilters();
                }
                _this.getStoredFilterForView();
                _this.reInitColumnTotal();
                if (tt) {
                    tt = tt.map(function (element, k) {
                        if (_this.rowCalculations) {
                            element = _this.rowCalculateFun(element);
                        }
                        for (var i in element) {
                            if (element.hasOwnProperty(i)) {
                                if (_this.cardVars[i] && _this.cardVars[i][element[i]]) {
                                    _this.cardVars[i][element[i]]['count'] += 1;
                                }
                                if (_this.colCalculations[i] != null && _this.colCalculations[i] !== undefined && element[i] &&
                                    parseFloat(_this.colCalculations[i]) !== NaN && parseFloat(element[i]) !== NaN) {
                                    _this.colCalculations[i] = parseFloat(_this.colCalculations[i]) + parseFloat(element[i]);
                                }
                                if (_this.inlineEditCols[i]) {
                                    element[i] = _this.inlineEditCols[i][element[i]];
                                }
                            }
                        }
                        return element;
                    });
                }
                _this.rows = tt.slice();
                _this.reInitColumnTotal();
                _this.rows = tt.slice();
                _this.getSum();
                _this._changeDetectorRef.detectChanges();
            }).catch(function (err) {
                _this.helperService.openSnackBar(err);
                _this.loadingIndicator = false;
                console.info(err);
            });
        }
        else {
            console.log('No regId');
        }
    };
    DatatableComponent.prototype.isArray = function (tt) {
        return tt && typeof tt === 'object';
    };
    DatatableComponent.prototype.getFormatedDate = function (start, end) {
        start = start || new Date();
        end = end || new Date();
        return {
            $gte: moment(start).format('YYYY-MM-DD') + " " + this.timeFilter.startTime,
            $lte: moment(end).format('YYYY-MM-DD') + " " + this.timeFilter.endTime
        };
    };
    DatatableComponent.prototype.moveToInstanceList = function (url) {
        var tt = JSON.parse(localStorage.getItem('wsAppData')) || {};
        tt['processName'] = this.allData['integratedProcessName'];
        localStorage.setItem('wsAppData', JSON.stringify(tt));
        this.moveTo(url);
    };
    DatatableComponent.prototype.initDateFilter = function () {
        var startDate = new Date();
        var endDate = new Date();
        if (this.appData && this.appData.dataRangeTimeDiff) {
            var timeParam = this.appData.dataRangeTimeDiff.substr(this.appData.dataRangeTimeDiff.length - 1, this.appData.dataRangeTimeDiff.length);
            var timeValue = this.appData.dataRangeTimeDiff.substr(0, this.appData.dataRangeTimeDiff.length - 1);
            startDate = moment().subtract(timeValue, timeParam);
            startDate = startDate.startOf('day');
        }
        else {
            startDate.setDate(startDate.getDate() - 7);
            startDate.setHours(0, 0, 0, 0);
        }
        endDate.setHours(23, 59, 59, 999);
        this.timeFilter['startDate'] = startDate;
        this.timeFilter['endDate'] = endDate;
    };
    DatatableComponent.prototype.reopenFilters = function () {
        if (this.dataTablePastRequestData && this.dataTablePastRequestData.openedFilters) {
            this.allData = this.allData || {};
            this.postGetData();
            this.allData.filters = this.allData.filters || {};
            if (this.dataTablePastRequestData.openedFilters.advanceFilter !== null && this.dataTablePastRequestData.openedFilters.advanceFilter !== undefined) {
                this.allData.filters.noAdvanceFiltersSelected = this.dataTablePastRequestData.openedFilters.advanceFilter;
            }
            if (this.dataTablePastRequestData.openedFilters.dateRangeFilter !== null && this.dataTablePastRequestData.openedFilters.dateRangeFilter !== undefined) {
                this.allData.showDateRange = this.dataTablePastRequestData.openedFilters.dateRangeFilter;
            }
            if (this.dataTablePastRequestData.openedFilters.superFilter !== null && this.dataTablePastRequestData.openedFilters.superFilter !== undefined) {
                this.allData.showSuperFilter = this.dataTablePastRequestData.openedFilters.superFilter;
            }
        }
    };
    DatatableComponent.prototype.getRowClass = function (row) {
        return {
            'happ-summary': row.allSum
        };
    };
    DatatableComponent.prototype.rowCalculateFun = function (element) {
        for (var i in this.rowCalculations) {
            if (this.rowCalculations[i]['fields']) {
                element[i] = null;
                // tslint:disable-next-line:forin
                for (var j = 0; j < this.rowCalculations[i]['fields'].length; j++) {
                    var k = j + 1;
                    if (!element[this.rowCalculations[i]['fields'][j]['operand']] ||
                        (element[this.rowCalculations[i]['fields'][j]['operand']] &&
                            parseFloat(element[this.rowCalculations[i]['fields'][j]['operand']]) === NaN)) {
                        element[this.rowCalculations[i]['fields'][j]['operand']] = 0;
                    }
                    else {
                        element[this.rowCalculations[i]['fields'][j]['operand']] = parseFloat(element[this.rowCalculations[i]['fields'][j]['operand']]);
                    }
                    switch (this.rowCalculations[i]['fields'][j]['operator']) {
                        case '+':
                            if (element[i] == null ||
                                element[i] === undefined) {
                                element[i] =
                                    element[this.rowCalculations[i]['fields'][j]['operand']] +
                                        (this.rowCalculations[i]['fields'][k] &&
                                            element[this.rowCalculations[i]['fields'][k]['operand']]
                                            ? parseFloat(element[this.rowCalculations[i]['fields'][k]['operand']])
                                            : 0);
                                j = j + 1;
                            }
                            else {
                                element[i] +=
                                    element[this.rowCalculations[i]['fields'][j]['operand']];
                            }
                            break;
                        case '-':
                            if (element[i] == null ||
                                element[i] === undefined) {
                                element[i] =
                                    element[this.rowCalculations[i]['fields'][j]['operand']] -
                                        (this.rowCalculations[i]['fields'][k] &&
                                            element[this.rowCalculations[i]['fields'][k]['operand']]
                                            ? parseFloat(element[this.rowCalculations[i]['fields'][k]['operand']])
                                            : 0);
                                j = j + 1;
                            }
                            else {
                                element[i] -=
                                    element[this.rowCalculations[i]['fields'][j]['operand']];
                            }
                            break;
                        case '*':
                            if (element[i] == null ||
                                element[i] === undefined) {
                                element[i] =
                                    element[this.rowCalculations[i]['fields'][j]['operand']] *
                                        (this.rowCalculations[i]['fields'][k] &&
                                            element[this.rowCalculations[i]['fields'][k]['operand']]
                                            ? parseFloat(element[this.rowCalculations[i]['fields'][k]['operand']])
                                            : 1);
                                j = j + 1;
                            }
                            else {
                                element[i] *=
                                    element[this.rowCalculations[i]['fields'][j]['operand']];
                            }
                            break;
                        case '/':
                            if (element[i] == null ||
                                element[i] === undefined) {
                                element[i] =
                                    element[this.rowCalculations[i]['fields'][j]['operand']] /
                                        (this.rowCalculations[i]['fields'][k] &&
                                            element[this.rowCalculations[i]['fields'][k]['operand']]
                                            ? parseFloat(element[this.rowCalculations[i]['fields'][k]['operand']])
                                            : 1);
                                j = j + 1;
                            }
                            else {
                                if (element[this.rowCalculations[i]['fields'][j]['operand']] !== 0) {
                                    element[i] /=
                                        element[this.rowCalculations[i]['fields'][j]['operand']];
                                }
                            }
                            break;
                        case '%':
                            if (element[i] == null ||
                                element[i] === undefined) {
                                element[i] = element[this.rowCalculations[i]['fields'][j]['operand']] % (this.rowCalculations[i]['fields'][k] && element[this.rowCalculations[i]['fields'][k]['operand']]
                                    ? parseFloat(element[this.rowCalculations[i]['fields'][k]['operand']]) : 1);
                                j = j + 1;
                            }
                            else {
                                if (element[this.rowCalculations[i]['fields'][j]['operand']] !== 0) {
                                    element[i] = element[i] * (element[this.rowCalculations[i]['fields'][j]['operand']] / 100);
                                }
                            }
                            break;
                        default:
                    }
                }
                element[i] = element[i]
                    ? parseFloat(element[i]).toFixed(2)
                    : element[i];
            }
            else {
                element[i] = 0;
            }
        }
        return element;
    };
    DatatableComponent.prototype.ngAfterViewInit = function () {
        // this.formChartAndInlineEditVariable();
    };
    DatatableComponent.prototype.resetcolCalculationValues = function () {
        this.colCalculations = this.colCalculations || {};
        // tslint:disable-next-line:forin
        for (var i in this.colCalculations) {
            this.colCalculations[i] = 0;
        }
    };
    DatatableComponent.prototype.formChartAndInlineEditVariable = function () {
        var _this = this;
        this.allData = this.allData || {};
        this.postGetData();
        this.allData['columns'] = this.allData['columns'] || [];
        this.allData['columns'].forEach(function (element) {
            if (element.cellTemplate) {
                _this[element.cellTemplate]['render'] = element.render || [];
                element.cellTemplate = _this[element.cellTemplate];
            }
            if (element['control_type'] &&
                element['control_type']['control_type'] === 'calculation') {
                _this.rowCalculations[element['control_type']['label']] =
                    element['control_type'];
            }
            if (element['control_type'] &&
                element['control_type']['inline_edit'] &&
                element['control_type']['category'] === 'radio') {
                if (element['control_type']['show_on_card']) {
                    _this.cardVarsArray.push({
                        label: element['control_type']['label'],
                        options: element['control_type']['options']
                    });
                    _this.cardVars[element['control_type']['label']] = {};
                    element['control_type']['options'].forEach(function (ele) {
                        ele['count'] = 0;
                        _this.cardVars[element['control_type']['label']][ele.value || ele.title] = ele;
                    });
                }
                var options_1 = {};
                element['control_type']['options'].forEach(function (ele) {
                    options_1[ele.value || ele.title] = ele;
                });
                _this.inlineEditCols[element['control_type']['label']] = options_1;
            }
        });
        setTimeout(function () {
            _this.formatDataToChart();
        }, 500);
    };
    DatatableComponent.prototype.openScanqueueRecordsList = function () {
        this.router.navigate(["/offices/" + this.officeId + "/registries/scanqueuerecordslist/" + this.allData['regId']
        ]);
    };
    DatatableComponent.prototype.moveTo = function (url) {
        if (url.includes('./') || url[0] === '/') {
            this.router.navigate([url], { relativeTo: this.route });
        }
        else {
            this.router.navigate([this.router.url + '/' + url]);
        }
    };
    DatatableComponent.prototype.navigateTo = function (url) {
        this.router.navigate([url]);
    };
    DatatableComponent.prototype.inlineEdit = function (row, key, bool, col) {
        if (col && col.control_type && col.control_type.inline_edit) {
            if (!row.allSum) {
                if (this.inlineEditCell) {
                    for (var i in this.inlineEditCell) {
                        if (this.inlineEditCell.hasOwnProperty(i)) {
                            this.inlineEditCell[i] = false;
                        }
                    }
                }
                this.inlineEditCell[key] = bool;
            }
        }
    };
    DatatableComponent.prototype.validateNumber = function (e, value, control_type) {
        var current = value.value || '';
        var input = String.fromCharCode(e.charCode);
        var next = current + input;
        if (control_type && control_type.allow_decimal) {
            var reg = new RegExp("^\\d*(?:[.,]\\d{0," + control_type.decimal_places + "})?$");
            // const reg = /^\d*(?:[.,]\d{0,})?$/;
            if (!reg.test(next)) {
                e.preventDefault();
            }
        }
        // if (next && ((parseFloat(next) < control_type.min || parseFloat(next) > control_type.max))) {
        //   e.preventDefault();
        // }
    };
    DatatableComponent.prototype.actionMoveTo = function (rowIndex, application, data, button, url) {
        var _this = this;
        console.log("acrion move to");
        if (application === 'deleteRecord') {
            this.helperService.openConfirmPopUp('popup.are_you_sure_you_want_to_delete_record')
                .subscribe(function (res) {
                if (res) {
                    _this.helperService.deleteRequest("api/reg/" + _this.allData['regId'] + "/" + data['_id'])
                        .then(function () {
                        _this.getServerSideRecord();
                        _this.datatableService.onPositionRecordChange.next(true);
                        _this.helperService.openSnackBar('Deleted Successfully');
                    }).catch(function (err) {
                        _this.helperService.openSnackBar(err || 'Error in deleting record');
                        console.log('Error in deleting record', err);
                    });
                }
            });
        }
        else if (application === 'app.oneRecordView.edit.popup' ||
            application === 'app.oneRecordView.edit.sideMenu' ||
            application === 'app.oneRecordView.add.popup') {
            this.addLoading = true;
            setTimeout(function () {
                _this.addLoading = false;
            }, 5 * 1000);
            var recData = {};
            recData['regId'] = this.allData['regId'];
            recData['recId'] = data && data['_id'];
            recData['recData'] = data;
            recData['formFields'] = this.allData['columns'] || [];
            button.handler(recData);
        }
        else if (button.type === 'function') {
            var recData_1 = {};
            data = data || {};
            this.updateReg(data._id, data._id, data._id, '_id', rowIndex, data)
                .then(function (tempData) {
                recData_1['regId'] = _this.allData['regId'];
                recData_1['recId'] = data['_id'];
                recData_1['childId'] = _this.route.snapshot.params['childId'];
                recData_1['parentId'] = _this.route.snapshot.params['parentId'];
                // tslint:disable-next-line:max-line-length
                _this.helperService.getRequest("api/registries/" + recData_1['regId'] + "/registries-record/" + recData_1['recId'] + "/replicate-record-register/" + recData_1['childId'] + "?parentId=" + recData_1['parentId'])
                    .then(function () {
                    _this.snackBar.open('Saved', 'Okay', {
                        duration: 2000
                    });
                })
                    .catch(function (err) {
                    console.info(err);
                    _this.snackBar.open(err || 'Error Occured', 'Okay', {
                        duration: 2000
                    });
                });
                button.handler(recData_1);
            }).catch(function (err) {
                console.info(err);
            });
        }
        else if (url) {
            if (data) {
                this.allData.formId = (this.allData.ng5Form && this.allData.ng5Form._id);
                url = this.datatableService.formatUrl(url, this.allData);
                url = url.replace(':recId', data['_id']);
            }
            this.moveTo(url);
        }
        else if (application === 'app.message.start') {
            this.performAction(button, data);
        }
        else {
            if (button['placement']['headerActions']) {
                this.actionHandler(button);
            }
            else {
                this.actionClick(application, data, button);
            }
        }
    };
    DatatableComponent.prototype.actionClick = function (application, data, button) {
        var _this = this;
        if (button.type === 'function') {
            var recData = {};
            recData['regId'] = this.allData['regId'];
            recData['recId'] = data['_id'];
            recData['childId'] = this.route.snapshot.params['childId'];
            recData['parentId'] = this.route.snapshot.params['parentId'];
            // tslint:disable-next-line:max-line-length
            this.helperService
                .getRequest("api/registries/" + recData['regId'] + "/registries-record/" + recData['recId'] + "/replicate-record-register/" + recData['childId'] + "?parentId=" + recData['parentId'])
                .then(function () {
                _this.snackBar.open('Saved', 'Okay', {
                    duration: 2000
                });
            })
                .catch(function (err) {
                console.info(err);
                _this.snackBar.open(err || 'Error Occured', 'Okay', {
                    duration: 2000
                });
            });
            button.handler(recData);
        }
        else if (application === 'app.customregistries.openChildRegister') {
            this.allData['openChildRegister'](button.params, data);
        }
        else if (application === 'app.persona24') {
            this.allData['openPersonaEdit'](data['_id']);
        }
        else {
            this.datatableService['editClickHandle'](this.allData, data);
        }
    };
    DatatableComponent.prototype.reset = function () {
        this.resetFilterValues();
        this.getServerSideRecord();
    };
    DatatableComponent.prototype.resetFilterValues = function () {
        this.dateTimeFilterType = (this.appData && this.appData.defaultDateFilter) || '##df_all##';
        this.valueFilter = {
            'registredDataTime': (this.appData && this.appData.defaultDateFilter) || '##df_all##'
        };
        console.log("this.allData.filters.values 1", this.allData.filters.values);
        this.initDateFilter();
        this.resetValues();
        this.resetcolCalculationValues();
    };
    DatatableComponent.prototype.filterIconClicked = function () {
        this.resetValues();
        this.resetcolCalculationValues();
        this.allData.filters.noAdvanceFiltersSelected = !this.allData.filters.noAdvanceFiltersSelected;
        this.dateRangeFilter();
        this.fastQuery = '';
        if (!this.allData.filters.noAdvanceFiltersSelected) {
            this.filter('');
        }
    };
    DatatableComponent.prototype.resetValues = function () {
        this.processFilters = {};
        this.filters = {};
        this.searchText = '';
        this.allData = this.allData || {};
        this.postGetData();
        this.allData.filters = this.allData.filters || {};
        this.valueFilter = this.valueFilter || {};
        for (var i in this.valueFilter) {
            if (i !== 'registredDataTime') {
                delete this.valueFilter[i];
            }
        }
        console.log("this.allData.filters.values 666", this.allData.filters.values, this.valueFilter);
    };
    DatatableComponent.prototype.search = function () {
        this.page.pageNumber = 0;
        if (this.requestData && this.requestData.page) {
            this.requestData.page.pageNumber = 0;
        }
        this.getFilters();
        this.resetcolCalculationValues();
        this.getServerSideRecord();
    };
    DatatableComponent.prototype.excel = function () {
        var _this = this;
        var reqData = JSON.parse(JSON.stringify(this.requestData));
        reqData.reportType = 'excel';
        this.helperService.postRequest("api/reg/" + this.regId + "/report", this.requestData)
            .then(function (result) {
            var temp = {
                base64: result || '',
                mimetype: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                originalname: moment().format('YYYY:MM:DD HH:mm:ss') + ".xlsx"
            };
            _this.helperService.downloadDocument(temp);
        }).catch(function (err) {
            console.log(err);
        });
    };
    DatatableComponent.prototype.reportGenerationPopup = function (mqFilter) {
        var requestData = JSON.parse(JSON.stringify(this.requestData));
        if (mqFilter) {
            requestData.mqFilter = (requestData.mqFilter) ? requestData.mqFilter + " AND " + mqFilter : mqFilter;
        }
        var dialogRef = this.dialog.open(DtTableReportPopupComponent, {
            width: '600px',
            height: '600px',
            restoreFocus: false,
            panelClass: 'happ-form-builder-dialog',
            data: {
                requestData: requestData,
                regId: this.regId,
                templateList: this.appData.templateList || [],
                page: this.page
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
            }
        });
    };
    DatatableComponent.prototype.pdf = function () {
        var _this = this;
        // this.getFilters();
        // const pdfUrl = `${environment.serverUrl}api/registries/${
        //   this.allData['regId']
        //   }/reports/pdf/v2?filter=${JSON.stringify(this.requestData.filters)}`;
        // this.reportGenrate('pdf', pdfUrl);
        var reqData = JSON.parse(JSON.stringify(this.requestData));
        reqData.templateId = '5d12855d3bdb9d1316a53777';
        reqData.reportType = 'pdf';
        this.helperService.postRequest("api/reg/" + this.regId + "/report", reqData)
            .then(function (result) {
            var temp = {
                base64: result || '',
                mimetype: 'application/pdf',
                originalname: moment().format('YYYY:MM:DD HH:mm:ss') + ".pdf"
            };
            _this.helperService.downloadDocument(temp);
        }).catch(function (err) {
            console.log(err);
        });
    };
    DatatableComponent.prototype.reportGenrate = function (type, url) {
        var bodydata = {
            columns: this.allData.columns,
            defaultRegisterDateTimeSort: this.allData
                .defaultRegisterDateTimeSort,
            filterRecord: {},
            filters: this.requestData.filters,
            officeId: this.allData.officeId,
            page: this.allData.page,
            regId: this.allData.regId,
            search: {
                value: this.fastQuery || '',
                regex: false
            },
            sort: this.allData.sort
        };
        if (this.requestData['currentMQFilter'] && this.appData && this.appData['hardFilter']) {
            this.requestData['mqFilter'] = this.appData['hardFilter'] + " AND " + this.requestData['currentMQFilter'];
            // delete this.requestData['currentMQFilter'];
        }
        else if (this.requestData['currentMQFilter']) {
            this.requestData['mqFilter'] = this.requestData['currentMQFilter'];
            // delete this.requestData['currentMQFilter'];
        }
        else if (this.appData && this.appData['hardFilter']) {
            this.requestData['mqFilter'] = this.appData['hardFilter'];
        }
        bodydata['mqFilter'] = this.requestData['mqFilter'] || '';
        var win = window.open('_blank');
        var doc = win.document;
        doc.open('text/html');
        doc.write("<html><head><title>Report Generate</title></head>\n            <body style=\"display: flex;flex-direction: column;justify-content: center;align-items: center;text-align: center;min-height: 100vh;background-color: #f7f7f7;\">\n            <div><h3>Report Generate<h3/><br/><br/> <img src=\"https://rigp.pinbox24.com/assets/images/Processing.gif\"/></div>\n            <body></html>");
        var a = document.createElement('a');
        document.body.appendChild(a);
        this.downloadFile(url, bodydata, function (blob) {
            doc.close();
            var urlBlob = URL.createObjectURL(blob);
            a.href = urlBlob;
            a.download =
                new Date().toISOString().slice(0, 10) + '_Report.' + type;
            a.click();
            win.close();
        });
    };
    DatatableComponent.prototype.downloadFile = function (url, body, success) {
        var xhr = new XMLHttpRequest();
        xhr.open('POST', url, true);
        var credentials = JSON.parse(localStorage.getItem('ngStorage-token')) || {};
        xhr.setRequestHeader('Authorization', credentials.token || '');
        xhr.setRequestHeader('officeId', this.allData.officeId || '');
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        xhr.send(JSON.stringify(body));
        xhr.responseType = 'blob';
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (success) {
                    success(xhr.response);
                }
            }
        };
        xhr.send(null);
    };
    DatatableComponent.prototype.filter = function (query) {
        this.page.pageNumber = 0;
        if (this.requestData && this.requestData.page) {
            this.requestData.page.pageNumber = 0;
        }
        this.searchText = query;
        this.resetcolCalculationValues();
        this.getServerSideRecord();
    };
    DatatableComponent.prototype.dateRangeFilter = function () {
        // this.allData.showDateRange = !this.allData.showDateRange;
        // if (!this.allData.filters.noAdvanceFiltersSelected) {
        //   this.allData.filters.hideDateRange = false;
        // }
        if (this.allData.showDateRange) {
            var startDate = new Date();
            var endDate = new Date();
            startDate.setDate(startDate.getDate() - 7);
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 999);
            this.allData.filters['startDate'] = startDate;
            this.allData.filters['endDate'] = endDate;
        }
    };
    DatatableComponent.prototype.formatDataToChart = function () {
        var _this = this;
        this.chartData = [];
        this.cardVarsArray.forEach(function (ele) {
            _this.chartData[_this.chartData.length] = [];
            ele.options.forEach(function (ele2) {
                _this.chartData[_this.chartData.length - 1].push({
                    name: ele2.value || ele2.title,
                    value: _this.cardVars[ele.label][ele2.value || ele2.title]
                        .count
                });
            });
        });
    };
    DatatableComponent.prototype.getFilters = function () {
        if (this.datatableService && this.datatableService.instanceFilters && Object.keys(this.datatableService.instanceFilters)) {
            this.requestData['filterRecord'] = this.datatableService.instanceFilters;
        }
        else {
            this.requestData['filterRecord'] = {};
        }
        if ((this.allData.filters && this.allData.filters.noAdvanceFiltersSelected) || this.allData.showSuperFilter || this.allData.showDateRange) {
            console.log("this.allData.filters.values 777", this.allData.filters.values);
            this.valueFilter = this.valueFilter || {};
            if (this.allData && this.allData.showDateRange && this.dateTimeFilterType === '##df_customValue##') {
                // this.valueFilter['registredDataTime'] = this.getFormatedDate(this.timeFilter.startDate, this.timeFilter.endDate);
                this.requestData['filters'] = Object.assign(this.valueFilter, { 'registredDataTime': this.getFormatedDate(this.timeFilter.startDate, this.timeFilter.endDate) });
            }
            else {
                this.valueFilter['registredDataTime'] = this.dateTimeFilterType;
                this.requestData['filters'] = this.valueFilter;
            }
        }
        else {
            this.requestData['filters'] = {};
        }
        for (var i in this.requestData['filters']) {
            if (!this.requestData['filters'][i]) {
                delete this.requestData['filters'][i];
            }
        }
        this.filters = this.requestData['filters'];
    };
    DatatableComponent.prototype.testing = function (data) {
    };
    DatatableComponent.prototype.updateReg = function (recId, value, prevVal, key, rowIndex, allValue) {
        if (allValue === void 0) { allValue = {}; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var tt, data, i, i, err_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        if (!this.allData['regId']) {
                            this.snackBar.open('ERROR: Register Id not found', 'Okay', {
                                duration: 2000
                            });
                            return [2 /*return*/];
                        }
                        console.log(recId, value, prevVal, key, rowIndex, allValue);
                        allValue[key] = value;
                        allValue = this.calculationOnSave(allValue);
                        tt = JSON.parse(JSON.stringify(allValue));
                        delete tt['_id'];
                        data = {};
                        this.processFilters = {};
                        data[key] = value;
                        return [4 /*yield*/, this.helperService.updateRegisterRecord(this.allData['regId'], recId, tt)];
                    case 1:
                        _a.sent();
                        if (this.allData['refreshOnEdit']) {
                            this.getServerSideRecord();
                        }
                        else {
                            this.getSum();
                            this.datatableService.onPositionRecordChange.next(true);
                            if (this.cardVars[key]) {
                                this.cardVars[key][value].count += 1;
                                if (this.cardVars[key][prevVal.value || prevVal.title]) {
                                    this.cardVars[key][prevVal.value || prevVal.title].count -= 1;
                                }
                                this.formatDataToChart();
                            }
                            if (this.inlineEditCols[key]) {
                                this.rows[rowIndex][key] = this.inlineEditCols[key][value];
                            }
                            else {
                                this.rows[rowIndex][key] = value;
                            }
                            this.rowCalculateFun(this.rows[rowIndex]);
                            if (this.colCalculations &&
                                Object.keys(this.colCalculations).length > 0) {
                                for (i in this.colCalculations) {
                                    if (i !== 'allSum') {
                                        this.colCalculations[i] = 0;
                                    }
                                }
                                this.rows.forEach(function (element, k) {
                                    if (!element['allSum']) {
                                        for (var i in element) {
                                            if (_this.colCalculations[i] != null &&
                                                _this.colCalculations[i] !== undefined &&
                                                element[i] &&
                                                parseFloat(_this.colCalculations[i]) !==
                                                    NaN &&
                                                parseFloat(element[i]) !== NaN) {
                                                _this.colCalculations[i] =
                                                    parseFloat(_this.colCalculations[i]) + parseFloat(element[i]);
                                            }
                                        }
                                    }
                                });
                                this.summaryArray = [];
                                if (this.allData && this.allData.application === 'position') {
                                    this.allData.positionParentUpdate(this.colCalculations);
                                }
                                // format
                                this.colCalculations = this.colCalculations || {};
                                for (i in this.colCalculations) {
                                    if (i !== 'allSum') {
                                        this.colCalculations[i] =
                                            this.colCalculations[i] &&
                                                this.colCalculations[i].toFixed(2);
                                    }
                                }
                                this.summaryArray.push(this.colCalculations);
                            }
                            this.rows = this.rows.slice();
                        }
                        this.updateParent();
                        return [2 /*return*/, true];
                    case 2:
                        err_1 = _a.sent();
                        console.info(err_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DatatableComponent.prototype.updateParent = function () {
        var _this = this;
        if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) &&
            JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['apps'] &&
            this.allData['extra'] &&
            this.allData['extra']['GDPR-RES-ID']) {
            var GDPR_Resource = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['apps']['gdpr-resources']['id'];
            var count = {
                conclusion1: this.cardVars['GDPR-Q-CON']['Zgodność']['count'] || 0,
                conclusion2: this.cardVars['GDPR-Q-CON']['Do usprawnienia']['count'] ||
                    0,
                conclusion3: this.cardVars['GDPR-Q-CON']['Potencjalne ryzyko']['count'] || 0,
                conclusion4: this.cardVars['GDPR-Q-CON']['Niezgodny']['count'] || 0
            };
            this.helperService
                .updateRegisterRecord(GDPR_Resource, this.allData['extra']['GDPR-RES-ID'], count)
                .then(function (data) { })
                .catch(function (err) {
                console.info(err);
                _this.snackBar.open(err || 'Error occured while updating data', 'Okay', {
                    duration: 2000
                });
            });
        }
    };
    DatatableComponent.prototype.actionHandler = function (type) {
        if (type.application === 'app.customregistries.openChildRegister') {
            this.allData['openChildRegister'](type);
        }
        else {
            this.datatableService['AddClickHandle'](this.allData);
        }
    };
    DatatableComponent.prototype.openWSAppsSettings = function (tabIndex) {
        var _this = this;
        tabIndex = tabIndex || 0;
        if (!this.appData.integratedProcessId) {
            this.appData.integratedProcessId = this.allData['integratedProcessId'];
        }
        var dialogRef = this.dialog.open(WsAppsSettingsComponent, {
            width: '100%',
            height: '100%',
            panelClass: 'happ-form-builder-dialog',
            data: {
                appData: this.appData,
                appList: this.workspaceData.appList,
                wsInfo: this.wsInfo,
                processRoles: this.appData.processRoles,
                requiredDataFields: this.appData.requiredDataFields,
                gvRequiredFields: this.appData.gvRequiredFields,
                tabIndex: tabIndex
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.appData = result;
                _this.checkWSRequiredField();
            }
        });
    };
    DatatableComponent.prototype.openBulActionPopup = function (action) {
        var _this = this;
        var isTask = false;
        this.makeReqBody(null, null);
        var taskObject = {};
        if (action && action.application === 'app.message.start') {
            isTask = true;
            taskObject = action.taskInfo.task;
        }
        var dialogRef = this.dialog.open(RecordBulkUpdateComponent, {
            width: '100%',
            height: '100%',
            panelClass: 'happ-form-builder-dialog',
            data: {
                isTask: isTask,
                taskObject: taskObject,
                regId: this.allData.regId,
                regName: this.allData.regName,
                columnFields: this.allData.columns,
                fields: JSON.parse(JSON.stringify(this.helperService.getFormFlatField(this.allData.ng5Form['ng5Form']))),
                dtTableFilter: (this.requestData),
                mqHardFilter: (this.allData && this.allData.mqHardFilterString),
                recCount: (this.page && this.page.totalElements) || 0
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.getServerSideRecord();
            }
        });
    };
    DatatableComponent.prototype.openDescriptionPopup = function (control_type, value) {
        var dialogRef = this.dialog.open(DescriptionPopupComponent, {
            width: control_type && control_type.fullWidth ? '100%' : '60%',
            height: control_type && control_type.fullWidth ? '100%' : '60%',
            panelClass: 'happ-form-builder-dialog',
            restoreFocus: false,
            data: { type: 'html', control_type: control_type, value: (value || (control_type && control_type.data)) }
        });
    };
    DatatableComponent.prototype.openInstructionPopup = function (data) {
        var dialogRef = this.dialog.open(DescriptionPopupComponent, {
            width: '60%',
            height: '60%',
            restoreFocus: false,
            panelClass: 'happ-form-builder-dialog',
            data: { type: 'data', value: data }
        });
    };
    DatatableComponent.prototype.savePrivateFilter = function () {
        var _this = this;
        this.confirmDialogRef = this.dialog.open(InputDialogComponent, {
            disableClose: false
        });
        this.confirmDialogRef.componentInstance.inputPlaceholder =
            'Nazwa filtru';
        this.confirmDialogRef.componentInstance.confirmMessage =
            'Utwórz nowy filtr';
        this.confirmDialogRef.componentInstance.inputValue = '';
        this.confirmDialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.privateFilter.addPrivateFilter(_this.dataTablesParameters['filters'], result, _this.allData.regId);
            }
            _this.confirmDialogRef = null;
        });
    };
    DatatableComponent.prototype.triggerPrivateFilter = function (eve) {
        this.filters = eve || {};
        this.getServerSideRecord();
    };
    DatatableComponent.prototype.isInt = function (n) {
        return Number(n) === n && n % 1 === 0;
    };
    DatatableComponent.prototype.isFloat = function (n) {
        return Number(n) === n && n % 1 !== 0;
    };
    DatatableComponent.prototype.toggleSidebar = function (name) {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    };
    DatatableComponent.prototype.updateParentRecord = function () {
        var _this = this;
        this.getSums(true, this.requestData)
            .then(function (data) {
            _this.summaryArray = _this.summaryArray || [];
            _this.summaryArray[0] = _this.summaryArray[0] || {};
            data = (data) || {};
            delete data['_id'];
            // tslint:disable-next-line:forin
            for (var i in data) {
                if (data[i] && _this.isFloat(data[i])) {
                    _this.summaryArray[0][i] = data[i];
                }
                else if (data[i]) {
                    _this.summaryArray[0][i] = parseFloat(parseFloat(data[i]).toFixed(2));
                }
            }
            _this.summaryArray = _this.summaryArray.slice();
            _this.helperService.updateRegisterRecord(_this.datatableService.params['regId'], _this.datatableService.params['recId'], data)
                .then(function () {
                _this.datatableService.onRecordUpdated.next(data);
            }).catch(function (err) {
                console.info(err);
            });
        }).catch(function (err) {
            console.info(err);
        });
    };
    DatatableComponent.prototype.getSum = function () {
        var _this = this;
        if (this.allData && this.allData.summingColumns && this.allData.summingColumns.length) {
            this.getSums(false, this.requestData)
                .then(function (data) {
                _this.summaryArray = _this.summaryArray || [];
                _this.summaryArray[0] = _this.summaryArray[0] || {};
                data = (data && data[0]) || {};
                delete data['_id'];
                // tslint:disable-next-line:forin
                for (var i in data) {
                    if (data[i] && _this.isFloat(data[i])) {
                        _this.summaryArray[0][i] = parseFloat(parseFloat(data[i]).toFixed(2));
                    }
                    else if (data[i]) {
                        _this.summaryArray[0][i] = data[i];
                    }
                }
                _this.summaryArray = _this.summaryArray.slice();
            }).catch(function (err) {
                console.info(err);
            });
        }
    };
    DatatableComponent.prototype.getSums = function (isPosition, requestData) {
        if (isPosition) {
            // delete requestData['filter'];
            // delete requestData['mqFilter'];
        }
        requestData['columns'] = this.allData.summingColumns || [];
        return this.helperService.postRequest("api/registries/sum/" + this.regId, requestData);
    };
    DatatableComponent.prototype.downloadPdf = function (docId) {
        var _this = this;
        this.helperService
            .getDocument(docId)
            .then(function (docData) {
            if (docData) {
                _this.helperService.downloadDocument(docData);
            }
            else {
                _this.snackBar.open('Data is null', 'Okay', {
                    duration: 2000
                });
            }
        })
            .catch(function (err) {
            _this.snackBar.open(err || 'Some error Occured', 'Okay', {
                duration: 2000
            });
        });
    };
    DatatableComponent.prototype.saveFilterToOffice = function () {
        var _this = this;
        this.helperService.openInputPopUp('Filter Name', 'You are about to save current filter in office document', '')
            .subscribe(function (data) {
            if (data) {
                _this._officeHelper.getLocalOfficeData()
                    .then(function (officeData) {
                    var tempFilter = {
                        name: data,
                        regId: _this.regId,
                        regName: _this.allData['regName']
                    };
                    if (_this.requestData) {
                        if (_this.requestData.filterRecord && Object.keys(_this.requestData.filterRecord).length) {
                            tempFilter['filter'] = _this.requestData.filterRecord;
                        }
                        if (_this.requestData.filters && Object.keys(_this.requestData.filters).length) {
                            if (tempFilter['filter']) {
                                // tslint:disable-next-line:forin
                                for (var i in _this.requestData.filters) {
                                    tempFilter['filter'][i] = _this.requestData.filters[i];
                                }
                            }
                            else {
                                tempFilter['filter'] = _this.requestData.filters;
                            }
                        }
                        if (tempFilter['filter'] && Object.keys(tempFilter['filter']).length) {
                            tempFilter['filter'] = JSON.stringify(tempFilter['filter']);
                        }
                        if (_this.requestData.mqFilter) {
                            tempFilter['mqFilter'] = _this.requestData.mqFilter;
                        }
                    }
                    var officeFilters = officeData.officeFilters || [];
                    officeFilters.push(tempFilter);
                    _this._officeHelper.updateOfficeDoc({ officeFilters: officeFilters })
                        .then(function () {
                        _this._officeHelper.refreshLocalOfficeData();
                        _this.refreshOfficeFilter++;
                        _this.helperService.showInfo('info_and_errors.office_filter_saved', 'Success:', 'succcess');
                    }).catch(function (err) {
                        _this._officeHelper.refreshLocalOfficeData();
                        console.info(err);
                    });
                }).catch(function (err) {
                    console.info(err);
                });
            }
        });
    };
    DatatableComponent.prototype.calculationOnSave = function (data) {
        var _this = this;
        var tt = this.helperService.getXTypeVariables((this.allData.ng5Form && this.allData.ng5Form['ng5Form']), 'calculation_on_save') || [];
        tt = this.helperService.transformSort(tt, 'calculationSort') || [];
        tt.forEach(function (ele) {
            var sum = 0;
            if (ele['fields']) {
                sum += _this.helperService.rowCalculateOnSave(ele, data);
            }
            data[ele['label']] = sum;
            // this is to save title too..
            if (ele['saveRange']) {
                ele['range'] = ele['range'] || [];
                ele['range'].forEach(function (range) {
                    if (sum >= range.min && sum <= range.max) {
                        data[ele['rangeLabel']] = range.title || sum;
                    }
                });
            }
        });
        return data;
    };
    DatatableComponent.prototype.getColumnSummary = function (ele) {
        return "--";
    };
    DatatableComponent.prototype.officeFilterClicked = function (filter) {
        if (filter) {
            if (filter.filter) {
                var tt = JSON.parse(filter.filter);
                this.requestData.filters = this.requestData.filters || {};
                // tslint:disable-next-line:forin
                for (var i in tt) {
                    this.requestData.filters[i] = tt[i];
                }
            }
            this.getServerSideRecord(null, filter.mqFilter);
        }
    };
    DatatableComponent.prototype.performAction = function (action, data) {
        var _this = this;
        if (action && action.application === 'app.message.start') {
            this.helperService.postRequest("api/registries/execute_task/" + this.regId + "/" + data._id, { taskObject: action.taskInfo.task })
                .then(function (data) {
                _this.helperService.openSnackBar('Task Executed');
                _this.getServerSideRecord();
            }).catch(function (err) {
                console.info(err);
            });
        }
    };
    DatatableComponent.prototype.moveToProcessSettings = function (processId) {
        this.router.navigate(["/offices/" + this.officeId + "/process-settings/process-settings-list/process-settings-list/" + processId]);
    };
    DatatableComponent.prototype.hardFilterClicked = function (value) {
        if (this.appData && this.appData.pullAllDataToView) {
            // this.requestData['currentMQFilter'] = value; 
            this.mqFilterString = value;
            var tt = (localStorage.getItem('wsAppData') && JSON.parse(localStorage.getItem('wsAppData'))) || {};
            var appSlug = tt['appUrlSlug'] || tt['appSlug'];
            localStorage.setItem("$" + appSlug, value);
            // this.rows = [...this.rows];
        }
        else {
            this.resetValues();
            this.fastQuery = '';
            this.requestData['currentMQFilter'] = value;
            this.mqFilterString = value;
            this.getServerSideRecord();
        }
    };
    DatatableComponent.prototype.cardOptionChanged = function (_a) {
        var _this = this;
        var optionData = _a.optionData, recId = _a.recId;
        var updatedDoc = {};
        if (this.regId && optionData) {
            var dataIndex = _.findIndex(this.rows, { _id: recId });
            if (dataIndex !== -1 && this.rows[dataIndex]) {
                this.rows[dataIndex][optionData.keyName] = optionData.value;
                this.rows = this.rows.slice();
            }
            updatedDoc[optionData.keyName] = optionData.value;
            this.helperService.updateRegisterRecord(this.regId, recId, updatedDoc)
                .then(function () {
                _this.helperService.showInfo('Updated the record', 'Success', 'success', { positionClass: 'toast-top-right', timeOut: 2000 });
            }).catch(function (err) {
                _this.helperService.showInfo(err, 'Error', 'error');
            });
        }
    };
    DatatableComponent.prototype.getStoredFilterForView = function () {
        // this.defaultHardFilter = null;
        var tt = (localStorage.getItem('wsAppData') && JSON.parse(localStorage.getItem('wsAppData'))) || {};
        var appSlug = tt['appUrlSlug'] || tt['appSlug'];
        if (localStorage.getItem("$" + appSlug)) {
            this.defaultHardFilter = localStorage.getItem("$" + appSlug);
            this.dateTimeFilterType = this.defaultHardFilter.dateTimeFilterType;
        }
        else {
            this.getDefaultHardFilter();
        }
    };
    DatatableComponent.prototype.getDefaultHardFilter = function () {
        if (!this.currentMQFilter && !this.defaultHardFilter) {
            this.allData.dtTableAdvanceFilters = this.allData.dtTableAdvanceFilters || [];
            var temp = this.allData.dtTableAdvanceFilters.filter(function (ele) { return (ele && ele.defaultFilter); });
            if (temp && temp.length) {
                this.defaultHardFilter = temp[0].value;
            }
        }
    };
    DatatableComponent.prototype.editRecord = function (recId) {
        if (recId === 'new') {
            var tempNew = (this.allData && this.allData.integratedProcessId) ? './recWithInstance' : './new';
            this.router.navigate([tempNew], { relativeTo: this.route });
        }
        else {
            this.router.navigate(["./" + recId], { relativeTo: this.route });
        }
    };
    DatatableComponent.prototype.refreshTable = function () {
        this.getServerSideRecord();
    };
    DatatableComponent.prototype.onCustomDateChange = function (eve, label) {
        console.log("eve  =", eve, label);
        if (eve === "##df_customValue##") {
            this.valueFilter['df_' + label] = this.valueFilter['df_' + label] || {
                startDate: moment().format("YYYY-MM-DD"),
                startTime: "00:00",
                endDate: moment().add(7, "day").format("YYYY-MM-DD"),
                endTime: "23:59"
            };
        }
        else {
            delete this.valueFilter['df_' + label];
        }
    };
    DatatableComponent.prototype.inputValueChanged = function (temp, val, fieldLabel) {
        if (temp.key === ',' || temp.key === ' ') {
            if (val) {
                val = val.substr(0, (val.length - 1));
            }
            this.addItem(temp, val, fieldLabel);
        }
        this.filterValue[fieldLabel] = this._filterStates(val);
    };
    DatatableComponent.prototype.addItem = function (eve, val, fieldLabel) {
        val = (eve && eve.option && eve.option.value) || val;
        var tempFilter = this.filterValue[fieldLabel].map(function (ele) { return (ele.tag); });
        console.log("val", val, this.items, tempFilter);
        this.items[fieldLabel] = this.items[fieldLabel] || [];
        if (tempFilter.includes(val) && !this.items[fieldLabel].includes(val)) {
            this.items[fieldLabel].push(val);
            this.valueFilter.$or = this.valueFilter.$or || [];
            this.valueFilter.$or = this.items[fieldLabel].map(function (ele) {
                var _a;
                return (_a = {}, _a[fieldLabel] = { $regex: "((^" + ele + "$|^" + ele + ",)|," + ele + ",|," + ele + "$)" }, _a);
            });
        }
    };
    DatatableComponent.prototype.removeItem = function (ele, fieldLabel) {
        console.log(ele);
        this.items[fieldLabel] = this.items[fieldLabel] || [];
        if (this.items[fieldLabel].includes(ele)) {
            this.items[fieldLabel].splice(this.items[fieldLabel].indexOf(ele), 1);
            this.valueFilter.$or = this.valueFilter.$or || [];
            this.valueFilter.$or = this.items[fieldLabel].map(function (ele) {
                var _a;
                return (_a = {}, _a[fieldLabel] = { $regex: "((^" + ele + "$|^" + ele + ",)|," + ele + ",|," + ele + "$)" }, _a);
            });
        }
    };
    DatatableComponent.prototype._filterStates = function (value) {
        var filterValue = (value && value.toLowerCase());
        return this.officeTags.filter(function (state) { return state.tag && state.tag.toLowerCase().indexOf(filterValue) === 0; });
    };
    DatatableComponent.prototype.getOfficeTags = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, error_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this;
                        return [4 /*yield*/, this.helperService.getRequest("api/record-tag")];
                    case 1:
                        _a.officeTags = _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _b.sent();
                        console.log("Err = ", error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DatatableComponent.prototype.postGetData = function () {
        var _this = this;
        if (this.allData.filters && this.allData.filters.values) {
            this.allData.filters.values.forEach(function (ele) {
                if (ele.control_type === 'input-tag') {
                    _this.filterValue[ele.label] = JSON.parse(JSON.stringify(_this.officeTags));
                }
            });
        }
        else {
            this.filterValue = {};
        }
    };
    DatatableComponent.prototype.makeRefreshTable = function () {
        this.refreshTable();
    };
    return DatatableComponent;
}());
export { DatatableComponent };
